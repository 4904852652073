@use "variables";
@import "mixins";

#contact {
  height: 700px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .contactBackgroundContainer {
    z-index: -100;
    background-image: url("../assets/svg/footerBackgroundImage.svg");
    background-repeat: repeat-x;
    height: 700px;
    width: 100%;
    position: absolute;
    background-size: contain;
  }
  .contactContainer {
    max-width: 600px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  h1 {
    color: var(--light-001, #dde6f6);
    text-align: center;
    font-size: 64px;
    font-family: Manrope;
    font-weight: 800;
  }
  p {
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    color: variables.$light005;
  }
  h2 {
    color: variables.$accent;
    text-align: center;
    font-size: 20px;
    font-weight: 200;
  }
  h3 {
    color: variables.$light005;
    text-align: center;
    font-size: 20px;
    font-weight: 300;
    padding-top: 20px;
  }
  .sayHello {
    margin-top: 30px;
  }
}

@media (max-width: variables.$tabWidth) {
  #contact {
    h1 {
      font-size: 40px;
    }

    h3 {
      font-size: 18px;
    }
  }
}

@media (max-width: variables.$themeWidth) {
  #contact {
    .contactBackgroundContainer {
      background-size: cover;
    }
  }
}
